import React from 'react';
import AppFooterLarge from './AppFooterLarge';
import AppHeaderMarketing from './AppHeaderMarketing';

const TextPageLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <AppHeaderMarketing logoLook="solid" />
    <main>{children}</main>
    <AppFooterLarge />
  </>
);

export default TextPageLayout;
