import React from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import { LinkButton } from 'web/components/elements';
import ScrollIntoViewByHash from 'web/components/ScrollIntoViewByHash';
import themeConstants from 'web/styles/themeConstants';
import { defaultPricingPlanId, pricingPlans } from 'web/utils/pricingPlans';

const FaqTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 40px;
  ${themeConstants.typography.hero}
`;

const FaqEntry = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

const FaqEntryTitle = styled.div`
  display: flex;
  justify-content: space-between;

  cursor: pointer;
`;

const FaqEntryBody = styled.div`
  margin-top: 24px;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const FaqEntryHeading = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
`;

const FAQsContainer = styled.div`
  ${themeConstants.media.md} {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 48px;
  }
`;

const FaqSection = () => (
  <section>
    <Container>
      <FaqTitle>Common questions about pricing</FaqTitle>
      <FAQsContainer>
        <FaqEntry>
          <FaqEntryTitle>
            <FaqEntryHeading>Do you offer a free plan?</FaqEntryHeading>
          </FaqEntryTitle>
          <FaqEntryBody>
            Yes, our free plan has no monthly fees. You only pay{' '}
            {pricingPlans[defaultPricingPlanId].applicationFeePercentage * 100}% Introwise commission plus payment
            processing fees when someone makes a booking with you. It&apos;s a great plan if you&apos;re just starting
            out!
          </FaqEntryBody>
        </FaqEntry>
        <FaqEntry>
          <FaqEntryTitle>
            <FaqEntryHeading>Is there a setup fee?</FaqEntryHeading>
          </FaqEntryTitle>
          <FaqEntryBody>
            No, we made it easy and affordable for you! It will take you around 10 minutes to set up your full account
            and start sharing the link with your clients.
          </FaqEntryBody>
        </FaqEntry>
        <FaqEntry>
          <FaqEntryTitle>
            <FaqEntryHeading>Do you charge a commission on my payouts?</FaqEntryHeading>
          </FaqEntryTitle>
          <FaqEntryBody>
            Our Free and Growth plans come with a small commission, plus you pay payment processing fees. There is no
            Introwise commission on our Pro plan. You only pay a low payment processing fee set by Stripe or PayPal.
          </FaqEntryBody>
        </FaqEntry>
        <FaqEntry>
          <FaqEntryTitle>
            <FaqEntryHeading>Can I change the plan after the purchase?</FaqEntryHeading>
          </FaqEntryTitle>
          <FaqEntryBody>
            Yes, you can always change plans under the Payments tab in your Dashboard. You can downgrade to the Free
            plan with no monthly fee, or upgrade to the next plan that gives you a lower commission and additional
            features.
          </FaqEntryBody>
        </FaqEntry>
        <ScrollIntoViewByHash id="call-minutes">
          <FaqEntry>
            <FaqEntryTitle>
              <FaqEntryHeading>How are call minutes counted?</FaqEntryHeading>
            </FaqEntryTitle>
            <FaqEntryBody>
              Call minutes are only counted for built-in Introwise calls. If you&apos;re using the Zoom integration, or
              are meeting offline, your session duration doesn&apos;t count towards the minutes usage. Call minutes are
              counted per participant and group sessions will consume your quota proportionally faster.
            </FaqEntryBody>
          </FaqEntry>
        </ScrollIntoViewByHash>
        <ScrollIntoViewByHash>
          <FaqEntry>
            <FaqEntryTitle>
              <FaqEntryHeading>Can I purchase additional call minutes?</FaqEntryHeading>
            </FaqEntryTitle>
            <FaqEntryBody>
              If you run out of your included monthly Introwise call minutes you can always purchase extra call minutes.
              Purchased minutes do not expire and you can use them anytime. Current minutes price starts at $5 per 1000
              extra minutes.
            </FaqEntryBody>
          </FaqEntry>
        </ScrollIntoViewByHash>
        <FaqEntry>
          <FaqEntryTitle>
            <FaqEntryHeading>Is there a free trial?</FaqEntryHeading>
          </FaqEntryTitle>
          <FaqEntryBody>
            Yes! There is a risk-free 14-days free trial on our Growth and Pro plans. Don&apos;t worry - we&apos;ll send
            you a reminder before your trial is over. You can also take advantage of our Free plan to try Introwise.
          </FaqEntryBody>
        </FaqEntry>
      </FAQsContainer>
      <div style={{ marginTop: 40, marginBottom: 40 }}>
        <h2>Have more questions?</h2>
        <LinkButton to="/faq" secondary md style={{ marginRight: 24, marginBottom: 12 }}>
          Read our general FAQ
        </LinkButton>
        <LinkButton to="/contact" primary md>
          Contact us
        </LinkButton>
      </div>
    </Container>
  </section>
);

export default FaqSection;
