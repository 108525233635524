import { faInfoCircle, faStar, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import { InlineButton, LinkButton, LinkStyled } from 'web/components/elements';
import Flare from 'web/components/Flare';
import UserContext from 'web/components/UserContext';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';
import {
  PricingPlanId,
  pricingPlans,
  PricingPlanSubscription,
  subscriptionPricingAnnualPriceId,
  subscriptionPricingPlanId,
  subscriptionPricingPriceId,
} from 'web/utils/pricingPlans';
import PricingTable from './PricingTable';

const PricingTitle = styled.h1`
  ${themeConstants.typography.hero}
  text-align: left;
  margin-top: 40px;
  margin-bottom: 12px;

  ${themeConstants.media.md} {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 4px;
  }
`;

const PricingSubTitle = styled.p`
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  color: ${themeVars.color.halfMuted};
  margin-top: 0px;
  margin-bottom: 12px;
  ${themeConstants.media.md} {
    text-align: center;
    margin-bottom: 36px;
  }
`;
const PlanPrice = styled.span<{ small?: boolean }>`
  color: #000;
  ${themeConstants.typography.h4};
  ${themeConstants.media.sm} {
    ${({ small }) => (small ? themeConstants.typography.h3 : themeConstants.typography.h1)};
  }
`;

const MobileOnly = styled.div<{ display?: string }>`
  display: ${({ display }) => display || 'initial'};
  ${themeConstants.media.sm} {
    display: none;
  }
`;

const DesktopOnly = styled.div<{ display?: string }>`
  display: none;
  ${themeConstants.media.sm} {
    display: ${({ display }) => display || 'initial'};
  }
`;

const MinutesLink = () => (
  <LinkStyled to="#call-minutes" style={{ color: themeVars.color.muted }}>
    <FontAwesomeIcon icon={faInfoCircle} />
  </LinkStyled>
);

const planPayAsYouGoId: PricingPlanId = 'commission_2_5';
const planPayAsYouGo = pricingPlans[planPayAsYouGoId];
const planGrowthId: PricingPlanId = 'growth_1_5';
const planGrowth = pricingPlans[planGrowthId] as PricingPlanSubscription;
const priceGrowth = planGrowth.prices['monthly_usd_1500'];
const priceGrowthAnnual = planGrowth.prices['annual_usd_14400'];
const planSubscription = pricingPlans[subscriptionPricingPlanId] as PricingPlanSubscription;
const priceSubscription = planSubscription.prices[subscriptionPricingPriceId];
const priceSubscriptionAnnual = planSubscription.prices[subscriptionPricingAnnualPriceId];

const isLifetimePlan = (planId: PricingPlanId) => pricingPlans[planId].type === 'lifetime';

const PlansTables = ({ currentPlan, yearly }: { currentPlan: PricingPlanId; yearly: boolean }) => (
  <>
    <PricingTable>
      <thead>
        <tr>
          <td>No {yearly ? 'annual' : 'monthly'} fees</td>
          <td>
            <div>Less commission</div>
          </td>
          <td>
            <div>
              <DesktopOnly as="span">
                <FontAwesomeIcon icon={faStar} style={{ color: '#D7C259', marginRight: 12, fontSize: 20 }} />{' '}
              </DesktopOnly>
              Most popular
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <h2>Free</h2>
            <p>
              <PlanPrice>$0 </PlanPrice> <br />
              USD
            </p>
            <p>
              <PlanPrice small>{planPayAsYouGo.applicationFeePercentage * 100}% </PlanPrice>
              <br />
              commission
            </p>
            <p style={{ marginTop: 8 }}>Pay as you go</p>
          </th>
          <th>
            <h2>Growth</h2>
            <p>
              <PlanPrice>${(yearly ? priceGrowthAnnual : priceGrowth).amount / 100 / (yearly ? 12 : 1)} </PlanPrice>
              <br />
              USD/month
            </p>
            <p>
              <PlanPrice small>{planGrowth.applicationFeePercentage * 100}% </PlanPrice>
              <br />
              commission
            </p>
            {yearly && <p style={{ marginTop: 8 }}>Billed annually</p>}
          </th>
          <th>
            <h2>Pro</h2>
            <p>
              <PlanPrice>
                ${(yearly ? priceSubscriptionAnnual : priceSubscription).amount / 100 / (yearly ? 12 : 1)}{' '}
              </PlanPrice>{' '}
              <br />
              USD/month
            </p>
            <p>
              <PlanPrice small>{planSubscription.applicationFeePercentage * 100}% </PlanPrice>
              <br />
              commission
            </p>
            {yearly && <p style={{ marginTop: 8 }}>Billed annually</p>}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={3}>Scheduling</td>
        </tr>
        <tr>
          <td>Paid and free services</td>
          <td>Paid and free services</td>
          <td>Paid and free services</td>
        </tr>
        <tr>
          <td>Scheduling of 1:1 sessions</td>
          <td>Scheduling of 1:1 sessions</td>
          <td>Scheduling of 1:1 sessions</td>
        </tr>
        <tr>
          <td>Group sessions</td>
          <td>Group sessions</td>
          <td>Group sessions</td>
        </tr>
        <tr>
          <td>Unlimited clients and services</td>
          <td>Unlimited clients and services</td>
          <td>Unlimited clients and services</td>
        </tr>
        <tr>
          <td>Google Calendar integration</td>
          <td>Google Calendar integration</td>
          <td>Google Calendar integration</td>
        </tr>
        <tr>
          <td>Outlook Calendar integration</td>
          <td>Outlook Calendar integration</td>
          <td>Outlook Calendar integration</td>
        </tr>
        <tr>
          <td>Apple Calendar integration</td>
          <td>Apple Calendar integration</td>
          <td>Apple Calendar integration</td>
        </tr>
        <tr>
          <td>Two connected calendars</td>
          <td>Two connected calendars</td>
          <td>
            <b>Four connected calendars</b>
          </td>
        </tr>
        <tr>
          <td>Single availability</td>
          <td>
            <b>Multiple availabilities</b>
          </td>
          <td>
            <b>Multiple availabilities</b>
          </td>
        </tr>
        <tr>
          <td>Buffers, minimum notice and frequency rules</td>
          <td>
            <b>Customizable rules per service</b> <Flare variant="success">New</Flare>
          </td>
          <td>
            <b>Customizable rules per service</b> <Flare variant="success">New</Flare>
          </td>
        </tr>
        <tr>
          <td>Self-service cancellation and rescheduling</td>
          <td>Self-service cancellation and rescheduling</td>
          <td>
            <b>Cancellation and rescheduling rules</b> <Flare variant="success">New</Flare>
          </td>
        </tr>
        <tr>
          <td>
            <b>Packages</b>
          </td>
          <td>
            <b>Packages</b>
          </td>
          <td>
            <b>Packages</b>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <b>Time-limited packages</b>
          </td>
          <td>
            <b>Time-limited packages</b>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>Booking page</td>
        </tr>
        <tr>
          <td>Custom URL</td>
          <td>Custom URL</td>
          <td>Custom URL</td>
        </tr>
        <tr>
          <td>Fixed branding</td>
          <td>
            <b>Custom branding</b>
          </td>
          <td>
            <b>Custom branding</b>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <b>Custom domain</b>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>Payment processing</td>
        </tr>
        <tr>
          <td>
            <b>Payment processing by Stripe</b>
          </td>
          <td>
            <b>Payment processing by Stripe</b>
          </td>
          <td>
            <b>Payment processing by Stripe</b>
          </td>
        </tr>
        <tr>
          <td>
            <b>Payment processing by PayPal</b>
          </td>
          <td>
            <b>Payment processing by PayPal</b>
          </td>
          <td>
            <b>Payment processing by PayPal</b>
          </td>
        </tr>
        <tr>
          <td>
            <>{planPayAsYouGo.applicationFeePercentage * 100}% Introwise commission</>
          </td>
          <td>
            <>{planGrowth.applicationFeePercentage * 100}% Introwise commission</>
          </td>
          <td>
            <b>{planSubscription.applicationFeePercentage * 100}% Introwise commission</b>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <b>Discount codes</b>
          </td>
          <td>
            <b>Discount codes</b>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <b>Payment plans</b> <Flare variant="success">New</Flare>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <b>Stripe local payment methods</b>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>Business management</td>
        </tr>
        <tr>
          <td>
            <b>Clients dashboard (CRM)</b>
          </td>
          <td>
            <b>Clients dashboard (CRM)</b>
          </td>
          <td>
            <b>Clients dashboard (CRM)</b>
          </td>
        </tr>
        <tr>
          <td>
            <b>Clients notes</b>
          </td>
          <td>
            <b>Clients notes</b>
          </td>
          <td>
            <b>Clients notes</b>
          </td>
        </tr>
        <tr>
          <td>Sessions history</td>
          <td>Sessions history</td>
          <td>Sessions history</td>
        </tr>
        <tr>
          <td>Packages usage tracking</td>
          <td>Packages usage tracking</td>
          <td>Packages usage tracking</td>
        </tr>
        <tr>
          <td colSpan={3}>Automation</td>
        </tr>
        <tr>
          <td>
            <b>Custom email reminders</b>
          </td>
          <td>
            <b>Custom email reminders</b>
          </td>
          <td>
            <b>Custom email reminders</b>
          </td>
        </tr>
        <tr>
          <td>
            <b>Custom email notes and follow-ups</b> <Flare variant="success">New</Flare>
          </td>
          <td>
            <b>Custom email notes and follow-ups</b> <Flare variant="success">New</Flare>
          </td>
          <td>
            <b>Custom email notes and follow-ups</b> <Flare variant="success">New</Flare>
          </td>
        </tr>
        <tr>
          <td>
            <b>Zapier integrations</b>
          </td>
          <td>
            <b>Zapier integrations</b>
          </td>
          <td>
            <b>Zapier integrations</b>
          </td>
        </tr>
        <tr>
          <td>
            <b>Custom automation actions</b>
          </td>
          <td>
            <b>Custom automation actions</b>
          </td>
          <td>
            <b>Custom automation actions</b>
          </td>
        </tr>
        <tr>
          <td>One workflow</td>
          <td>
            <b>Multiple workflows</b>
          </td>
          <td>
            <b>Multiple workflows</b>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>Video calls</td>
        </tr>
        <tr>
          <td>
            <b>Built-in HD video calls</b>
          </td>
          <td>
            <b>Built-in HD video calls</b>
          </td>
          <td>
            <b>Built-in HD video calls</b>
          </td>
        </tr>
        <tr>
          <td>
            1000 call minutes/month <MinutesLink />
          </td>
          <td>
            <b>2000 call minutes/month</b> <MinutesLink />
          </td>
          <td>
            <b>6000 call minutes/month</b> <MinutesLink />
          </td>
        </tr>
        <tr>
          <td>Virtual backgrounds</td>
          <td>
            <b>Custom virtual backgrounds</b>
          </td>
          <td>
            <b>Custom virtual backgrounds</b>
          </td>
        </tr>
        <tr>
          <td>Files sharing</td>
          <td>Files sharing</td>
          <td>Files sharing</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <b>Recording to the cloud</b> <Flare variant="warning">Beta</Flare>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <b>Live streaming</b> <Flare variant="warning">Beta</Flare>
          </td>
        </tr>
        <tr>
          <td>Zoom integration</td>
          <td>Zoom integration</td>
          <td>Zoom integration</td>
        </tr>
        <tr>
          <td colSpan={3}>
            <b>
              <LinkStyled to="#core-features">All other features</LinkStyled> plus
            </b>
          </td>
        </tr>
        <tr>
          <td>Email and chat support</td>
          <td>Email and chat support</td>
          <td>
            <b>Email, chat, and video support</b>
          </td>
        </tr>
        <DesktopOnly as="tr" display="table-row">
          <td></td>
          <td></td>
          <td>
            <b>Free account setup call</b>
          </td>
        </DesktopOnly>
        {!currentPlan && (
          <tr>
            <td>
              <LinkButton
                className="cta-button"
                to="/get-started?plan=free"
                primary
                style={{ width: '100%', textAlign: 'center' }}
              >
                Start for free
              </LinkButton>
            </td>
            <td>
              <LinkButton
                className="cta-button"
                to={`/get-started?plan=growth-${yearly ? 'yearly' : 'monthly'}`}
                primary
                style={{ width: '100%', textAlign: 'center' }}
              >
                Start free trial
              </LinkButton>
            </td>
            <td>
              <LinkButton
                className="cta-button"
                to={`/get-started?plan=pro-${yearly ? 'yearly' : 'monthly'}`}
                primary
                style={{ width: '100%', textAlign: 'center' }}
              >
                Start free trial
              </LinkButton>
            </td>
          </tr>
        )}
        {currentPlan && !isLifetimePlan(currentPlan) && (
          <tr>
            <td>
              <LinkButton
                className="cta-button"
                to={
                  currentPlan === planPayAsYouGoId
                    ? '/dashboard/account/billing'
                    : '/dashboard/account/billing/subscription/cancel'
                }
                secondary
                style={{ width: '100%', textAlign: 'center' }}
              >
                {currentPlan === planPayAsYouGoId ? 'Manage your plan' : 'Switch to this plan'}
              </LinkButton>
            </td>
            <td>
              <LinkButton
                className="cta-button"
                to={
                  currentPlan === planPayAsYouGoId
                    ? '/dashboard/account/billing/subscription'
                    : currentPlan === planGrowthId
                    ? '/dashboard/account/billing'
                    : '/dashboard/account/billing/subscription/downgrade'
                }
                secondary
                style={{ width: '100%', textAlign: 'center' }}
              >
                {currentPlan === planGrowthId && 'Manage your plan'}
                {currentPlan === planPayAsYouGoId && 'Upgrade to this plan'}
                {currentPlan === 'subscription' && 'Switch to this plan'}
              </LinkButton>
            </td>
            <td>
              <LinkButton
                className="cta-button"
                to={
                  currentPlan === planPayAsYouGoId
                    ? '/dashboard/account/billing/subscription'
                    : currentPlan === 'subscription'
                    ? '/dashboard/account/billing'
                    : '/dashboard/account/billing/subscription/upgrade'
                }
                secondary={currentPlan === 'subscription'}
                primary={currentPlan !== 'subscription'}
                style={{ width: '100%', textAlign: 'center' }}
              >
                {currentPlan === 'subscription' ? 'Manage your plan' : 'Upgrade to this plan'}
              </LinkButton>
            </td>
          </tr>
        )}
        {currentPlan && isLifetimePlan(currentPlan) && (
          <tr>
            <DesktopOnly as="td" display="table-cell" colSpan={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
              You have a lifetime deal. Enjoy! 🎉
            </DesktopOnly>
            <MobileOnly as="td" display="table-cell" colSpan={2} style={{ textAlign: 'center', fontWeight: 'bold' }}>
              You have a lifetime deal. Enjoy! 🎉
            </MobileOnly>
          </tr>
        )}
      </tbody>
    </PricingTable>
    {/* <MobileOnly style={{ marginTop: 12 }}>
      <div style={{ padding: 20 }}>
        <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faShieldAlt} style={{ color: '#D7C259', marginRight: 24, fontSize: 40 }} />
          <div>Yearly subscription plans are covered by our 30-day money-back guarantee</div>
        </div>
      </div>
    </MobileOnly> */}
  </>
);

const ToggleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  column-gap: 4px;
  margin-bottom: 28px;
  > div:first-child {
    text-align: right;
  }
  > div:last-child {
    text-align: left;
  }
`;

const ToggleButton = styled(InlineButton)`
  vertical-align: bottom;
  font-size: 24px;
  line-height: 24px;
`;

const PlansSection = () => {
  const { userData } = useContext(UserContext);
  const [yearly, setYearly] = useState(false);
  return (
    <section>
      <Container>
        <PricingTitle>Our fair pricing</PricingTitle>
        <PricingSubTitle>
          We only earn when you earn. Start for free with all the necessary tools included and upgrade as your business
          grows.
        </PricingSubTitle>
        <ToggleContainer>
          <div>Monthly</div>
          <div>
            <ToggleButton onClick={() => setYearly((prev) => !prev)}>
              <FontAwesomeIcon
                icon={yearly ? faToggleOn : faToggleOff}
                title={yearly ? 'Switch to monthly' : 'Switch to yearly'}
                titleId="pricing-icon-billing-swtich"
              />
            </ToggleButton>
          </div>
          <div>Yearly</div>
        </ToggleContainer>
        <PlansTables currentPlan={userData?.pricingPlanId as PricingPlanId | undefined | null} yearly={yearly} />
      </Container>
    </section>
  );
};

export default PlansSection;
