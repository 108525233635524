import React, { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { isClientSide } from 'web/utils/ssr';

const ScrollIntoViewByHashImpl = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>();
  useLayoutEffect(() => {
    if (location.hash === `#${props.id}`) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    props.id,
    location.hash,
    // Clicking multiple times on the same link doesn't change the hash,
    // but it does change the key. We listen to key changes to replicate
    // default browser behaviour of scrolling to the target every time
    // a #hash link is clicked.
    location.key,
  ]);
  return <div {...props} ref={ref} />;
};

const ScrollIntoViewByHash = (props: React.HTMLAttributes<HTMLDivElement>) =>
  isClientSide ? <ScrollIntoViewByHashImpl {...props} /> : <div {...props} />;

export default ScrollIntoViewByHash;
