import React from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import ScrollIntoViewByHash from 'web/components/ScrollIntoViewByHash';
import themeConstants from 'web/styles/themeConstants';

const Features = styled.div``;

const FeaturesItem = styled.div`
  margin-bottom: 30px;
`;

const FeatureItemTitle = styled.div`
  > h2 {
    ${themeConstants.typography.h1}
  }
`;

const ClampedP = styled.p`
  max-width: 780px;
`;

const WhatsIncludedTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 40px;
  ${themeConstants.typography.hero}
`;

const FeaturesList = styled.ul`
  padding-left: 0px;
  list-style: none;
  > li {
    line-height: 32px;
    padding-left: 40px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m14.705 8.3945-3.784 4.969-1.633-2.086c-0.34-0.436-0.97-0.512-1.404-0.171-0.435 0.34-0.512 0.969-0.171 1.403l2.431 3.1069c0.19 0.242 0.48 0.383 0.788 0.383h7e-3c0.309-1e-3 0.601-0.147 0.788-0.394l4.569-5.9999c0.335-0.44 0.25-1.066-0.19-1.401-0.441-0.335-1.067-0.249-1.401 0.19zm-2.7046 11.605c-4.411 0-8-3.589-8-8 0-4.4109 3.589-7.9999 8-7.9999s8 3.589 8 7.9999c0 4.411-3.589 8-8 8zm0-18c-5.523 0-10 4.478-10 9.9999 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.5219-4.477-9.9999-10-9.9999z' clip-rule='evenodd' fill='%232B2116' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    background-position: 0 3px;
    background-size: 28px;
    background-repeat: no-repeat;

    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

const FeaturesSection = () => (
  <section style={{ backgroundColor: '#FCFAF5', padding: '48px 0', marginBottom: 80, marginTop: 80 }}>
    <ScrollIntoViewByHash id="core-features">
      <Container>
        <WhatsIncludedTitle>Our core features</WhatsIncludedTitle>
        <ClampedP>
          Introwise is easy for you and your clients. Get more clients with Introwise by enabling a one-click booking &
          payment processing. Experts, who use Introwise, see at least 2x growth in the number of bookings because of a
          lower churn during the booking and payment process.
        </ClampedP>
        <Features>
          <FeaturesItem>
            <FeatureItemTitle>
              <h2>Scheduling</h2>
            </FeatureItemTitle>
            <ClampedP>
              Your time is so valuable, use it to expand your business. Introwise automates everything around scheduling
              sessions on your calendar, accepting bookings and sending information to you and your clients. With
              personal and group sessions, customizable services, automated emails and receipts we’ll make sure the day
              to day stuff runs smoothly.
            </ClampedP>
            <FeaturesList>
              <li>Easy scheduling based on your availability</li>
              <li>One-time or recurring availability rules</li>
              <li>Set a minimum notice time and a maximum date in the future where clients can book</li>
              <li>Buffer times before and after a session</li>
              <li>Customizable scheduling rules per service on the Growth plan</li>
              <li>Google Calendar, Microsoft Outlook Calendar, and Apple Calendar integrations with a two-way sync</li>
              <li>Two connected calendar accounts on all plans, and up to four on the Pro plan</li>
              <li>Create multiple availabilities and set different working hours for different services</li>
              <li>Multiple services on your booking pages</li>
              <li>Group sessions with individual bookings per participant</li>
              <li>Package multiple services together with automatic tracking of booked and remaining sessions</li>
              <li>
                Limit for how long a package can be used and set it to automatically expire after a specified number of
                days (on the Growth plan)
              </li>
              <li>Hide any service or package on your booking page to create &quot;secret&quot; offerings</li>
              <li>Sessions cancellation and rescheduling with a customizable message to the client</li>
              <li>Automated confirmation email with the call link and calendar invite to you and your client</li>
              <li>Calendar invite included in the confirmation email</li>
              <li>Self-service cancellation and rescheduling for your clients</li>
              <li>Set custom advance notice rules for cancellations and reschedulings (on the Pro plan)</li>
              <li>Automated receipt for your client</li>
            </FeaturesList>
          </FeaturesItem>
          <FeaturesItem>
            <FeatureItemTitle>
              <h2>Booking page</h2>
            </FeatureItemTitle>
            <ClampedP>
              You don&apos;t need a website. With Introwise booking page you can start accepting bookings in minutes.
              Customizable and SEO-optimized out-of-the-box.
            </ClampedP>
            <FeaturesList>
              <li>Choose your own booking page URL</li>
              <li>Make the page your own with a logo and a cover photo</li>
              <li>List as many services as you want in a single place</li>
              <li>
                Customize with your own branding in just a few clicks, no CSS knowledge needed (on the Growth plan)
              </li>
              <li>Connect up to 10 custom domains without worrying about SSL and hosting (on the Pro plan)</li>
              <li>Get found on Google thanks to the out-of-the-box SEO-optimization</li>
              <li>Streamlined checkout with built-in payment forms</li>
            </FeaturesList>
          </FeaturesItem>
          <FeaturesItem>
            <FeatureItemTitle>
              <h2>Payments</h2>
            </FeatureItemTitle>
            <ClampedP>
              Your time is valuable and we want you to get paid reliably and efficiently for your services. Connect a
              Stripe account, or a PayPal account, and processes payments from your clients anywhere in the world and
              receive money directly to your bank account.
            </ClampedP>
            <FeaturesList>
              <li>Accepting payments from any country with one-click checkout for your clients</li>
              <li>Low payment processing fees by Stripe and PayPal</li>
              <li>Accepting international Visa, Mastercard, Amex credit and debit cards, and PayPal payments</li>
              <li>Accepting Google Pay and Apple Pay payments with Stripe integration</li>
              <li>Accepting local payments methods, such as Bancontact and giropay, with Stripe integration</li>
              <li>Payments are processed automatically at the time of the booking</li>
              <li>Payment plans to automatically charge your clients on a regular interval</li>
              <li>No account is needed for your clients to book sessions with you and pay</li>
              <li>Assistance with card failures and declines due to bank issues</li>
            </FeaturesList>
          </FeaturesItem>
          <FeaturesItem>
            <FeatureItemTitle>
              <h2>Video calls</h2>
            </FeatureItemTitle>
            <ClampedP>
              We built HD video calls right into Introwise website, so it&apos;s easy for you and your clients to
              connect. No extra downloads required.
            </ClampedP>
            <FeaturesList>
              <li>In browser video calls, no need to install or download any software for you and your client</li>
              <li>
                Use your mobile or tablet with the same in-browser experience as on a desktop and connect from multiple
                devices at the same time
              </li>
              <li>Join calls with one click from your Introwise dashboard, an email or a calendar invite</li>
              <li>Group calls up to 42 people as simple as 1:1 calls</li>
              <li>
                An option for your clients to enter with an anonymous name for the grop calls where it&apos;s important
              </li>
              <li>Timer on a call to help you finish on time without reminding your clients</li>
              <li>Built-in chat for exchanging messages or links</li>
              <li>Built-in files sharing to quickly exchange document and images right on the call</li>
              <li>Virtual backgrounds and blur without any additional software</li>
              <li>Ability to upload your own custom virtual backgrounds (on the Growth plan)</li>
              <li>Parallel screen sharing for up to 2 people at the same time</li>
              <li>Share your whole screen audio or audio from a single tab while screensharing</li>
              <li>Recording calls directly to the cloud in 1080p HD quality (on the Pro plan)</li>
              <li>Live streaming calls to YouTube Live, LinkedIn Live, Facebook Live, and more (on the Pro plan)</li>
            </FeaturesList>
          </FeaturesItem>
          <FeaturesItem>
            <FeatureItemTitle>
              <h2>Business management</h2>
            </FeatureItemTitle>
            <ClampedP>
              Sessions history, itemized earnings, clients&apos; contacts - all of your customer relationships in one
              place. Introwise keeps your records neatly organized, and right at your fingertips.
            </ClampedP>
            <FeaturesList>
              <li>Single dashboard to view all your upcoming sessions</li>
              <li>Clients dashboard with basic info and emails (CRM)</li>
              <li>Client notes, timestamped and right along your client info</li>
              <li>Complete history of sessions with every client with an overview of your past earnings</li>
              <li>Packages tracking with an easy overview of booked and remaining sessions</li>
              <li>History of discount codes usage to easily track their performance</li>
              <li>Easy session cancellations</li>
              <li>One-click call joining right from your dashboard</li>
              <li>Access your business anywhere - from your desktop or on the go from the phone</li>
            </FeaturesList>
          </FeaturesItem>
          <FeaturesItem>
            <FeatureItemTitle>
              <h2>Automation</h2>
            </FeatureItemTitle>
            <ClampedP>
              Automatic reminders, custom workflows and integrations. With Introwise you can run your business on
              autopilot.
            </ClampedP>
            <FeaturesList>
              <li>Customizable email session reminders with custom messages</li>
              <li>Send reminders to clients or to yourself</li>
              <li>Send custom email notes and follow-ups any time before or after a session</li>
              <li>Create specific workflows for different services, series, and group sessions</li>
              <li>Automate your actions precisely on time with a variety of triggers</li>
              <li>Connect to 1,000s+ apps using Zapier, even on the Free plan</li>
              <li>Use custom automation actions in your workflows with Zapier</li>
            </FeaturesList>
          </FeaturesItem>
        </Features>
      </Container>
    </ScrollIntoViewByHash>
  </section>
);

export default FeaturesSection;
