import React from 'react';
import { Helmet } from 'react-helmet-async';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollToTopOnMount from 'web/components/ScrollToTopOnMount';
import TextPageLayout from 'web/components/TextPageLayout';
import FaqSection from './FaqSection';
import FeaturesSection from './FeaturesSection';
import PlansSection from './PlansSection';

const Pricing = () => (
  <>
    <Helmet title="Pricing" />
    <ScreenTracker screenName="Pricing" />
    <ScrollToTopOnMount />
    <TextPageLayout>
      <PlansSection />
      <FeaturesSection />
      <FaqSection />
    </TextPageLayout>
  </>
);

export default Pricing;
