import styled from 'styled-components';
import { Table } from 'web/components/elements';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const PricingTable = styled(Table)`
  table-layout: fixed;
  border-collapse: unset;

  /* Hide the third column on mobile */
  /* * > tr > *:nth-child(3) {
    display: none;
  }
  ${themeConstants.media.sm} {
    * > tr > *:nth-child(3) {
      display: table-cell;
    }
  } */

  /* Make mobile fonts smaller */
  font-size: 0.75em;
  .cta-button {
    padding: 5px 8px;
    font-size: 1em;
  }

  ${themeConstants.media.sm} {
    font-size: unset;
    .cta-button {
      padding: 13px 20px;
      font-size: unset;
    }
  }

  /* Vertical borders */
  * > tr {
    > *:first-child {
      border-left: 1px solid ${themeConstants.palette.peach[550]};
    }
    > * {
      border-right: 1px solid ${themeConstants.palette.peach[550]};
    }
  }

  /* Top row of the header borders */
  thead > tr:first-child > td {
    border-top: 1px solid ${themeConstants.palette.peach[550]};
    border-bottom: 1px solid ${themeConstants.palette.peach[550]};
  }

  /* Top radiuses */
  thead > tr:first-child {
    > td:nth-child(1) {
      border-top-left-radius: ${themeConstants.borderRadius.sm};
    }
    > td:nth-child(2) {
      border-top-right-radius: ${themeConstants.borderRadius.sm};
    }
    ${themeConstants.media.sm} {
      > td:nth-child(2) {
        border-top-right-radius: initial;
      }
      > td:nth-child(3) {
        border-top-right-radius: ${themeConstants.borderRadius.sm};
      }
    }
  }

  /* Bottom row borders */
  tbody > tr:last-child > td {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid ${themeConstants.palette.peach[550]};
  }

  /* Bottom radiuses */
  tbody > tr:last-child {
    > td:nth-child(1) {
      border-bottom-left-radius: ${themeConstants.borderRadius.sm};
    }
    > td:nth-child(2) {
      border-bottom-right-radius: ${themeConstants.borderRadius.sm};
    }
    > td:nth-child(1)[colspan='3'] {
      border-bottom-right-radius: ${themeConstants.borderRadius.sm};
    }
    > td:nth-child(2)[colspan='2'] {
      border-left: 1px solid ${themeConstants.palette.peach[550]};
      border-bottom-left-radius: ${themeConstants.borderRadius.sm};
    }
    ${themeConstants.media.sm} {
      > td:nth-child(2) {
        border-bottom-right-radius: unset;
      }
      > td:nth-child(3) {
        border-bottom-right-radius: ${themeConstants.borderRadius.sm};
      }
    }
  }

  /* Last row buttons */
  tbody > tr:last-child > td {
    vertical-align: middle;
    padding: 16px;
  }

  /* Header top row font style */
  thead > tr > td {
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    ${themeConstants.media.sm} {
      font-size: 18px;
    }
  }

  /* Header second row border fix */
  thead > tr:nth-child(2) > th {
    border-top: none;
  }

  /* Header second row font style */
  thead > tr > th {
    h2 {
      ${themeConstants.typography.h3};
      margin-top: 0;
      margin-bottom: 8px;
      ${themeConstants.media.sm} {
        margin-top: 16px;
        margin-bottom: 20px;
        ${themeConstants.typography.h2};
      }
    }
    p {
      color: ${themeVars.color.halfMuted};
      font-weight: normal;
      margin-top: 4px;
      margin-bottom: 4px;
      ${themeConstants.media.sm} {
        margin-top: 14px;
        margin-bottom: 14px;
        br {
          display: none;
        }
      }
    }
  }

  /* Header stickiness */
  thead > tr > th {
    top: 0;
    position: sticky;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    ${themeConstants.media.sm} {
      border-bottom: unset;
      position: unset;
    }
  }

  /* First content row border fix */
  tbody > tr:first-child > td {
    border-top: none;
  }

  /* Content rows padding */
  thead > tr > th,
  tbody > tr > td {
    padding: 8px 16px;
  }

  /* Middle headers */
  tbody > tr > td[colspan='3'] {
    border-top: 1px solid ${themeConstants.palette.peach[550]};
    border-bottom: 1px solid ${themeConstants.palette.peach[550]};
    font-size: 18;
    font-weight: bold;
    text-align: center;
  }
`;

export default PricingTable;
